import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`As my eyes, soak into`}<br parentName="p"></br>{`
`}{`our reflection.`}<br parentName="p"></br>{`
`}{`You stand out  like The Sleeping Venus,`}<br parentName="p"></br>{`
`}{`from an art collection.`}<br parentName="p"></br>{`
`}{`I’ve dreamt about you`}<br parentName="p"></br>{`
`}{`and this glittery day.`}<br parentName="p"></br>{`
`}{`I wish this relationship of ours`}<br parentName="p"></br>{`
`}{`to never be thrown away.`}</p>
    <p>{`You seem to appear in every`}<br parentName="p"></br>{`
`}{`daughter’s dream.`}<br parentName="p"></br>{`
`}{`But when I think of you,`}<br parentName="p"></br>{`
`}{`You’re in the color of cream.`}<br parentName="p"></br>{`
`}{`The silk fabric that is designed onto you,`}<br parentName="p"></br>{`
`}{`It surely will match,`}<br parentName="p"></br>{`
`}{`my white shimmery shoe!`}</p>
    <p>{`When you finally place, your length`}<br parentName="p"></br>{`
`}{`against my human skin.`}<br parentName="p"></br>{`
`}{`I hear my heart whisper,`}<br parentName="p"></br>{`
`}{`“This is where your new cycle begins”.`}<br parentName="p"></br>{`
`}{`You gifted me the voice`}<br parentName="p"></br>{`
`}{`of Beauty today.`}<br parentName="p"></br>{`
`}{`I will cherish this feeling and`}<br parentName="p"></br>{`
`}{`hope that it can stay.`}</p>
    <p>{`Your skin’s charming lushness,`}<br parentName="p"></br>{`
`}{`brings out the real tenderness.`}<br parentName="p"></br>{`
`}{`Your aesthetical lace,`}<br parentName="p"></br>{`
`}{`only fits my dynamical face.`}</p>
    <p>{`You will witness my next chapter.`}<br parentName="p"></br>{`
`}{`A new beginning`}<br parentName="p"></br>{`
`}{`that will be filled with laughter.`}<br parentName="p"></br>{`
`}{`You will soon be lifted upon`}<br parentName="p"></br>{`
`}{`that special stair.`}<br parentName="p"></br>{`
`}{`The traditional spot, where the two lovers`}<br parentName="p"></br>{`
`}{`will become one pair.`}</p>
    <p>{`I am one step closer, going from`}<br parentName="p"></br>{`
`}{`Miss to Mrs.`}<br parentName="p"></br>{`
`}{`My tan, chocolate cheeks will crave for my mother’s`}<br parentName="p"></br>{`
`}{`delicate kisses.`}<br parentName="p"></br>{`
`}{`My father and I, will have our`}<br parentName="p"></br>{`
`}{`last dance.`}<br parentName="p"></br>{`
`}{`It would be as romantic and magical`}<br parentName="p"></br>{`
`}{`as the Eiffel Tower`}<br parentName="p"></br>{`
`}{`in France.`}</p>
    <p>{`Your presence,`}<br parentName="p"></br>{`
`}{`has connected two families today.`}<br parentName="p"></br>{`
`}{`I vow to never let you go and to protect you`}<br parentName="p"></br>{`
`}{`all the way.`}<br parentName="p"></br>{`
`}{`You fill the eyes of my prince with love.`}<br parentName="p"></br>{`
`}{`As I walk down this aisle like a`}<br parentName="p"></br>{`
`}{`flying dove.`}</p>
    <p>{`The people present on this lovely night,`}<br parentName="p"></br>{`
`}{`Have come far way for me`}<br parentName="p"></br>{`
`}{`through a booked flight.`}<br parentName="p"></br>{`
`}{`Everything that is here,`}<br parentName="p"></br>{`
`}{`I will remember it,`}<br parentName="p"></br>{`
`}{`like a poem written by Shakespeare.`}</p>
    <p>{`Although, as I thank you`}<br parentName="p"></br>{`
`}{`for your part`}<br parentName="p"></br>{`
`}{`which sparkled My marriage.`}<br parentName="p"></br>{`
`}{`I promise to keep you forever with me,`}<br parentName="p"></br>{`
`}{`as soon as I sit`}<br parentName="p"></br>{`
`}{`in my wedding carriage.`}</p>
    <p>{`When I reach my new palace,`}<br parentName="p"></br>{`
`}{`You will be my first responsibility.`}<br parentName="p"></br>{`
`}{`As I will take care of you,`}<br parentName="p"></br>{`
`}{`through all my capability.`}<br parentName="p"></br>{`
`}{`Soon enough,`}<br parentName="p"></br>{`
`}{`You will be laid inside a glass case.`}<br parentName="p"></br>{`
`}{`I will treasure you, like a new souvenir`}<br parentName="p"></br>{`
`}{`for me to embrace.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      